import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MobileDownload from './helpers/MobileDownload';
import { MenuOutlined, PhoneTwoTone, MailTwoTone, CarTwoTone } from '@ant-design/icons';
import { Menu, Drawer, Button } from 'antd';


class TopMenu extends Component {
    render() {
        return (
            <div className="lvcc-menu-container">
                <Menu className="lvcc-menu" mode="horizontal" selectable={false}>
                    <Menu.Item key="home-img" className="home-img">
                        <Link to="/">
                            <span className="lvcc-logo" role="img" aria-label="Las Vegas Circus Center" />
                        </Link>
                    </Menu.Item>
                    <Menu.SubMenu key="classes" title={<>Classes</>}>
                        <Menu.Item key="beginners">
                            <Link to="/beginners">Beginners</Link>
                        </Menu.Item>
                        <Menu.Item key="kids">
                            <Link to="/kids">Kids</Link>
                        </Menu.Item>
                        <Menu.Item key="class-list">
                            <Link to="/classes">Class List</Link>
                        </Menu.Item>
                        <Menu.Item key="schedule">
                            <Link to="/schedule">Complete Schedule</Link>
                        </Menu.Item>
                        <Menu.Item key="privates">
                            <Link to="/appointments">Private Classes</Link>
                        </Menu.Item>
                        <Menu.Item key="open-gym">
                            <Link to="/open-gym">Open Gym</Link>
                        </Menu.Item>
                    </Menu.SubMenu>

                    <Menu.SubMenu key="camps" title={<>Camps</>}>
                        <Menu.Item key="Camps">
                            <Link to="/camps/aerial-arts">Aerial Arts</Link>
                        </Menu.Item>
                        <Menu.Item key="world-of-circus-camp">
                            <Link to="/camps/world-of-circus">World of Circus</Link>
                        </Menu.Item>
                        <Menu.Item key="circus-ninja-camp">
                            <Link to="/camps/circus-ninja">Circus Ninja</Link>
                        </Menu.Item>
                        <Menu.Item key="stunt-camp">
                            <Link to="/camps/stunt">Stunt Camp</Link>
                        </Menu.Item>
                    </Menu.SubMenu>

                    <Menu.SubMenu key="events" title={<>Events</>}>
                        <Menu.Item key="Shows">
                            <Link to="/events/shows">Shows</Link>
                        </Menu.Item>
                        <Menu.Item key="Group Events">
                            <Link to="/events/group-events">Group Events</Link>
                        </Menu.Item>
                        <Menu.Item key="workshops">
                            <Link to="/events/workshops">Workshops</Link>
                        </Menu.Item>
                        <Menu.Item key="auditions">
                            <Link to="/events/auditions">Auditions</Link>
                        </Menu.Item>
                    </Menu.SubMenu>

                    <Menu.SubMenu key="blog" title={<>Blog</>}>
                        <Menu.Item key="Path to Performance">
                            <Link to="/blog/path-to-performance">Path to Performance</Link>
                        </Menu.Item>
                    </Menu.SubMenu>


                    <Menu.Item key="register">
                        <a href="https://www.waiverking.com/print/27137" target="_blank" rel="noopener noreferrer">
                            Register
                        </a>
                    </Menu.Item>

                    <Menu.SubMenu key="about" title={<>About</>}>
                        <Menu.Item key="about-us">
                            <Link to="/about-us">About Us</Link>
                        </Menu.Item>
                        <Menu.Item key="instructors">
                            <Link to="/instructors">Instructors</Link>
                        </Menu.Item>
                        <Menu.Item key="policies">
                            <Link to="/policies">Safety Rules and Policies</Link>
                        </Menu.Item>
                        <Menu.Item key="newsletter">
                            <Link to="/newsletter">Newsletter</Link>
                        </Menu.Item>
                        <Menu.Item key="featured-art">
                            <Link to="/art/featured">Featured Art</Link>
                        </Menu.Item>
                        <Menu.Item key="jobs">
                            <Link to="/jobs">Jobs</Link>
                        </Menu.Item>
                    </Menu.SubMenu>


                </Menu>
            </div>
        );
    }
}


class TopMenuMobile extends Component {
    state = { visible: false };

    handleShowDrawer = () => {
        this.setState({
            visible: true
        });
    };

    handleCloseMenu = () => {
        this.setState({
            visible: false
        });
    };

    rootSubmenuKeys = ['classes', 'camps', 'events', 'about'];

    state = {
        openKeys: [],
    };

    handleOpenChange = openKeys => {
        const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
        if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            this.setState({ openKeys });
        } 
        else {
            this.setState({openKeys: latestOpenKey ? [latestOpenKey] : []});
        }
    };

    render() {
        return (
            <div>
                <Link to="/">
                    <span className="lvcc-logo" role="img" aria-label="Las Vegas Circus Center" />
                </Link>

                <Button 
                    className="lvcc-mobile-hamburger" 
                    type="primary" 
                    onClick={this.handleShowDrawer} 
                    icon={<MenuOutlined />}
                >
                    Menu
                </Button>
                <Drawer
                    placement="right"
                    width={300}
                    onClose={this.handleCloseMenu}
                    visible={this.state.visible}
                >
                    <Menu className="lvcc-mobile-menu"
                        mode="inline"
                        openKeys={this.state.openKeys}
                        onOpenChange={this.handleOpenChange}
                        onClick={this.handleCloseMenu}
                        selectable={false}
                    >
                        <Menu.Item key="call-us">
                            <a href="tel:1-833-324-7287"><PhoneTwoTone />Call Us</a>
                        </Menu.Item>
                        <Menu.Item key="email-us">
                            <a href="mailto:info@lasvegascircuscenter.com">
                                <MailTwoTone />E-mail Us
                            </a>
                        </Menu.Item>
                        <Menu.Item key="visit-us">
                            <a href="https://goo.gl/maps/y1KQs4QhQNNCtGYBA">
                                <CarTwoTone />Visit Us
                            </a>
                        </Menu.Item>


                        <Menu.SubMenu key="classes" title="Classes">
                            <Menu.Item key="beginners">
                                <Link to="/beginners">Beginners</Link>
                            </Menu.Item>
                            <Menu.Item key="kids">
                                <Link to="/kids">Kids</Link>
                            </Menu.Item>
                            <Menu.Item key="class-list">
                                <Link to="/classes">Class List</Link>
                            </Menu.Item>
                            <Menu.Item key="schedule">
                                <Link to="/schedule">Complete Schedule</Link>
                            </Menu.Item>
                            <Menu.Item key="privates">
                                <Link to="/appointments">Private Classes</Link>
                            </Menu.Item>
                            <Menu.Item key="open-gym">
                                <Link to="/open-gym">Open Gym</Link>
                            </Menu.Item>
                        </Menu.SubMenu>

                        <Menu.SubMenu key="camps" title={<>Camps</>}>
                            <Menu.Item key="aerial-arts-camp">
                                <Link to="/camps/aerial-arts">Aerial Arts</Link>
                            </Menu.Item>
                            <Menu.Item key="world-of-circus-camp">
                                <Link to="/camps/world-of-circus">World of Circus</Link>
                            </Menu.Item>
                            <Menu.Item key="circus-ninja-camp">
                                <Link to="/camps/circus-ninja">Circus Ninja</Link>
                            </Menu.Item>
                            <Menu.Item key="stunt-camp">
                                <Link to="/camps/stunt">Stunt Camp</Link>
                            </Menu.Item>
                        </Menu.SubMenu>

                        <Menu.SubMenu key="events" title="Events">
                            <Menu.Item key="Shows">
                                <Link to="/events/shows">Shows</Link>
                            </Menu.Item>
                            <Menu.Item key="Group Events">
                                <Link to="/events/group-events">Group Events</Link>
                            </Menu.Item>
                            <Menu.Item key="workshops">
                                <Link to="/events/workshops">Workshops</Link>
                            </Menu.Item>
                            <Menu.Item key="auditions">
                                <Link to="/events/auditions">Auditions</Link>
                            </Menu.Item>
                        </Menu.SubMenu>

                        <Menu.SubMenu key="blog" title="Blog">
                            <Menu.Item key="Path to Performance">
                                <Link to="/blog/path-to-performance">Path to Performance</Link>
                            </Menu.Item>
                        </Menu.SubMenu>

                        <Menu.Item key="register">
                            <a href="https://www.waiverking.com/print/27137" target="_blank" rel="noopener noreferrer">
                                Register
                            </a>
                        </Menu.Item>

                        <Menu.SubMenu key="about" title="About">
                            <Menu.Item key="about-us">
                                <Link to="/about-us">About Us</Link>
                            </Menu.Item>

                            <Menu.Item key="instructors">
                                <Link to="/instructors">Instructors</Link>
                            </Menu.Item>
                            <Menu.Item key="policies">
                                <Link to="/policies">Safety Rules and Policies</Link>
                            </Menu.Item>
                            <Menu.Item key="jobs">
                                <Link to="/jobs">Jobs</Link>
                            </Menu.Item>
                            <Menu.Item key="featured-art">
                                <Link to="/art/featured">Featured Art</Link>
                            </Menu.Item>
                            <Menu.Item key="newsletter">
                                <Link to="/newsletter">Newsletter</Link>
                            </Menu.Item>
                            <Menu.Item key="deadspace">
                            </Menu.Item>
                        </Menu.SubMenu>
           
                        <div style={{
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e8e8e8',
                            padding: '10px 16px',
                            textAlign: 'right',
                            left: 0,
                            background: '#fff',
                            borderRadius: '0 0 4px 4px'}}
                        >
                            <MobileDownload />
                        </div>

                    </Menu>

                </Drawer>
            </div>
        );
    }
}


export class Header extends Component {
    render() {
        return (
            <div className={this.props.width > 740 ? 'header' : 'header-mobile'}>
                {(this.props.width > 740) &&
                <div className="lvcc-quick-contact">
                    <a className="lvcc-email" href="mailto:info@lasvegascircuscenter.com">
                        info@lasvegascircuscenter.com
                    </a>
                    <a className="lvcc-phone" href="tel:1-833-324-7287"><PhoneTwoTone />
                        1-833-324-7287
                    </a>
                </div>
                }

                {/* The menu now renders conditionally, depending on the window width */}
                {this.props.width > 740 ? <TopMenu /> : <TopMenuMobile />}
            </div>
        );
    }
}
